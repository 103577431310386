import { createRouter, createWebHistory } from 'vue-router';

import {redirectIfNecessary, requireAuth} from "@/routes/authguard";

import adminRoutes from './AdminRoutes';
import authRoutes from './authRoutes';
import registrationRoutes from "./registrationRoutes";

const routes = [
  {
    name: 'registration',
    path: '',
    component: () => import(/* webpackChunkName: "general" */ '@/layout/GeneralLayout.vue'),
    children: [...registrationRoutes],
  },
  {
    name: 'Admin',
    path: '',
    component: () => import(/* webpackChunkName: "admin" */ '@/layout/withAdminLayout.vue'),
    children: [...adminRoutes],
    beforeEnter: requireAuth,
  },
  {
    name: 'Auth',
    path: '/auth',
    component: () => import(/* webpackChunkName: "auth" */ '@/layout/withAuthLayout.vue'),
    children: [...authRoutes],
    beforeEnter: redirectIfNecessary,
  },
];

const router = createRouter({
  history: createWebHistory(
    process.env.BASE_URL,
  ),
  linkExactActiveClass: 'active',
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = "EasyDoc | Digital Employee Wellness"
  next()
})


export default router;
