import axios from "axios";

export function apiNoAuth(){
    return axios.create({
        baseURL: 'https://zoie-rest.uk.r.appspot.com/api/',
        headers:{
            'ZH-API-Key': '9e68863eaaaab20c2219cbd2630e287e8efd4f137382cfb34ae7bd067e6ae662',
            'Authorization': 'Basic ZWFzeWRvY0B6b2llaGVhbHRoLmNvbTp6b2llQnVzaW5lc3M=',
            'Content-Type': 'application/json'
        }});
}
export function apiNoAuthMedia(){
    return axios.create({
        baseURL: 'https://zoie-rest.uk.r.appspot.com/api/',
        headers:{
            'ZH-API-Key': '9e68863eaaaab20c2219cbd2630e287e8efd4f137382cfb34ae7bd067e6ae662',
            'Authorization': 'Basic ZWFzeWRvY0B6b2llaGVhbHRoLmNvbTp6b2llQnVzaW5lc3M=',
            'Content-Type': 'multipart/form-data'
        }});
}

export function apiWithToken(authToken){
    return axios.create({
        baseURL: 'https://zoie-rest.uk.r.appspot.com/api/',
        headers:{
            'Authorization': 'Basic ' + authToken,
            'Content-Type': 'application/json'
        }});
}

export function apiWithTokenMedia(authToken){
    return axios.create({
        baseURL: 'https://zoie-rest.uk.r.appspot.com/api/',
        headers:{
            'Authorization': 'Basic ' + authToken,
            'Content-Type': 'multipart/form-data'
        }});
}