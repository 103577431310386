import Apps from './Applications';
import Components from './Components';
import FeaturesRoutes from './FeaturesRoutes';
import PageRoutes from './PageRoutes';

const routes = [
  {
    path: 'admin',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "Dashboard" */ '@/view/dashboard/index.vue'),
    children: [
      {
        path: '',
        name: 'admin-dashboard',
        component: () => import(/* webpackChunkName: "demoTwo" */ '@/view/dashboard/Dashboard.vue'),
      },{
        path: 'team/add',
        name: 'add-team',
        components: {
          default: () => import(/* webpackChunkName: "team-member-add" */ '../view/apps/users/AddUsers.vue'),
        }
      },{
        path: 'team/:memberId/view',
        name: 'view-member',
        props: true,
        component: () => import(/* webpackChunkName: "team-member" */ '../view/apps/users/TeamMember.vue')
      },
      {
        path: 'team/upload',
        name: 'upload-team',
        component: () => import(/* webpackChunkName: "team" */ '../view/apps/importExport/Import.vue'),
      },
      {
        path: 'team',
        name: 'view-team',
        component:  () => import(/* webpackChunkName: "team" */ '../view/apps/users/UserListDataTable.vue')
      },
      {
        path: 'team/:memberId/edit',
        name: 'edit-member',
        props: true,
        component: () => import(/* webpackChunkName: "addUser" */ '../view/apps/users/EditMember.vue'),
      },
      {
        path: 'team/:memberId/assess',
        name: 'assess-member',
        props: true,
        component: () => import(/* webpackChunkName: "addUser" */ '../view/apps/users/AssessMember.vue'),
      },
      {
        name: 'sick-notes',
        path: 'notes',
        component: () => import(/* webpackChunkName: "notes" */ '../view/apps/supportTicket/SickNotes.vue'),
      },
      {
        name: 'view-sick-note',
        path: 'note/:id',
        component: () => import(/* webpackChunkName: "noteDetails" */ '../view/apps/supportTicket/SickNoteDetails.vue'),
        props: true
      },
      {
        path: 'settings/billing',
        name: 'billing',
        component: () => import(/* webpackChunkName: "billing" */ '@/pages/settings/Billing.vue'),
      },
      {
        path: 'admins',
        name: 'adminDataTable',
        component: () => import(/* webpackChunkName: "dataTable" */ '../view/apps/users/AdminListDataTable.vue'),
      },
      {
        path: 'admins/add',
        name: 'add-admin',
        component: () => import(/* webpackChunkName: "info" */ '../view/apps/users/overview/AddAdmin.vue'),
      },
      {
        path: 'admins/:userId/edit',
        name: 'edit-admin',
        component: () => import(/* webpackChunkName: "editAdmin" */ '../view/apps/users/EditAdmin.vue'),
      },
      {
        path: 'admins/:userId/view',
        name: 'view-admin',
        component: () => import(/* webpackChunkName: "viewAdmin" */ '../view/apps/users/ViewAdmin.vue'),
      },
      {
        path: 'benefit-requests',
        name: 'benefit-requests-table',
        component:  () => import(/* webpackChunkName: "team" */ '../view/apps/benefits/BenefitRequests.vue')
      },
    ]
  },
  {
    path: '/crud',
    name: 'axios',
    component: () => import(/* webpackChunkName: "axios" */ '@/pages/settings/Billing.vue'),
    children: [
      {
        path: 'axios-view',
        name: 'axios-view',
        component: () => import(/* webpackChunkName: "axios-view" */ '@/view/crud/axios/View.vue'),
      },
      {
        path: 'axios-edit/:id',
        name: 'axios-edit',
        component: () => import(/* webpackChunkName: "axios-edit" */ '@/view/crud/axios/edit.vue'),
      },
      {
        path: 'axios-add',
        name: 'axios-add',
        component: () => import(/* webpackChunkName: "axios-add" */ '@/view/crud/axios/addNew.vue'),
      },
    ],
  },
  {
    path: '/crud',
    name: 'fireStore',
    component: () => import(/* webpackChunkName: "fireStore" */ '@/view/crud/fireStore/Index.vue'),
    children: [
      {
        path: 'fireStore-view',
        name: 'fireStore-view',
        component: () => import(/* webpackChunkName: "fireStore-view" */ '@/view/crud/fireStore/View.vue'),
      },
      {
        path: 'fireStore-edit/:id',
        name: 'fireStore-edit',
        component: () => import(/* webpackChunkName: "fireStore-edit" */ '@/view/crud/fireStore/edit.vue'),
      },
      {
        path: 'fireStore-add',
        name: 'fireStore-add',
        component: () => import(/* webpackChunkName: "fireStore-add" */ '@/view/crud/fireStore/addNew.vue'),
      },
    ],
  },
  ...PageRoutes,
  ...FeaturesRoutes,
  ...Components,
  ...Apps,
];

export default routes;
