import { apiWithToken } from "@/vuex/modules/authentication/axios/base"

const state = () => ({
  loading: false,
  error: null,
});

const actions = {
  async submitAssessment({ commit }, payload) {
    let token = localStorage.getItem('authToken');
    return apiWithToken(token)
    .post( `provider/event/${payload.eventId}/record`, payload.data)
    .then(response => {
      return response.data;
    })
    .catch(err => {
      commit('eventVisibleErr', err);
    })
  }
}

export default {
  namespaced: true,
  actions,
  state,
};
