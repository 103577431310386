<template>
  <template v-if="loading">
    <a-spin :size="size" />
  </template>
  <template v-else>{{ label }}</template>
</template>


<script>
import { defineComponent } from 'vue';
import VueTypes from 'vue-types';

const Loader = defineComponent({
  name: "Loader",
  props: { label: VueTypes.string, loading: VueTypes.bool.def(false), size: VueTypes.oneOf(["small", "large"]).def("small") }
});

export default Loader;
</script>