import auth from './modules/authentication/axios/actionCreator';
import business from './modules/business/actionCreator';
import businessOperations from './modules/businessOperations/actionCreator';
import { createStore } from 'vuex';
import gallery from './modules/gallery';
import notify from "./modules/notify/actionCreator";
import payfast from './modules/payfast/actionCreator';
import providers from './modules/providers/actionCreator';
import themeLayout from './modules/themeLayout/actionCreator';

export default createStore({
  modules: {
    notify: notify,
    auth: auth,
    business: business,
    businessOperations: businessOperations,
    gallery: gallery,
    payfast: payfast,
    providers: providers,
    themeLayout: themeLayout,
  },

});
