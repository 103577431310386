<template>
  <Container>
    <slot>
      <Wrapper :class="alert.type" :type="alert.type">
        <template v-if="alert.type === 'error'"><CloseCircleFilled /></template>
        <template v-else-if="alert.type === 'info'"><InfoCircleFilled /></template>
        <template v-else-if="alert.type === 'success'"><CheckCircleFilled /></template>
        <Alert
          :afterClose="afterClose"
          :description="alert.description"
          :message="alert.message"
          closable
        />
      </Wrapper>
    </slot>
  </Container>
</template>


<script>
import { CheckCircleFilled, CloseCircleFilled, InfoCircleFilled } from "@ant-design/icons-vue";
import { Alert } from "ant-design-vue";
import {defineComponent, onMounted} from 'vue';

import { Container, Wrapper } from './style';
import { useStore } from "vuex";

const Toast = defineComponent({
  name: "Toast",
  components: { Alert, CheckCircleFilled, CloseCircleFilled, Container, InfoCircleFilled, Wrapper },
  props: {
    alert: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const { dispatch } = useStore()

    onMounted(() => {
      setTimeout(() => {
        dispatch("notify/clearAlert");
      }, 3000);
    });

    const afterClose = () => {
      dispatch("notify/clearAlert");
    }

    return { afterClose }
  },
});

export default Toast;
</script>