import store from "@/vuex/store";

export function requireAuth(to, from, next) {
    if (!store.getters["auth/isAuthenticated"]) {
        store.commit("auth/setRedirectPath", to.fullPath);
        next("/auth/login");
    } else if (store.getters["auth/getUser"] === null) {
        store.dispatch("auth/reloadSession").then()
    }
    next();
}
export function redirectIfNecessary(to, from, next) {
    if (store.getters["auth/isAuthenticated"]) {
        let redirectPath = store.getters["auth/getRedirectPath"]
        if(redirectPath !== null && redirectPath !== undefined && redirectPath !== '') {
            store.commit('auth/clearRedirectPath')
            next(redirectPath)
        }
        next("/admin");
    } else {
        next();
    }
}