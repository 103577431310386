<template>
  <Style
    :message="message"
    :type="type"
    :description="description"
    :closable="closable && closable"
    :showIcon="showIcon && showIcon"
    :outlined="outlined"
    :closeText="closeText && closeText"
  >
    <template v-slot:description>
      <div>
        <slot name="description"></slot>
      </div>
    </template>
  </Style>
  <Errors class="ant-alert-error" v-if="errors">
    <div v-for="error in errors" :key="error.title">
      <p>{{ error.title }}</p>
      <ul>
        <li v-for="(issue, key) in error.issues" :key="`${error.title}.${key}`">
          <span>{{ issue.replace(/([A-Z])/g, '$1') }}</span>
        </li>
      </ul>
    </div>
  </Errors>
</template>


<script>
import { array, bool, any, oneOf, shape, string } from 'vue-types';
import { defineComponent } from 'vue';

import { Errors, Style } from './styled';

export default defineComponent({
  name: 'Alerts',
  components: {
    Errors,
    Style,
  },
  props: {
    closable: bool().def(false),
    closeText: string(),
    description: string(),
    errors: shape(array({
      title: string(),
      issues: {
        field: string(),
        message: string(),
      }
    })),
    icon: any,
    message: string(),
    outlined: bool().def(false),
    showIcon: bool().def(false),
    type: oneOf(['success', 'info', 'warning', 'error']).def('success'),
  },
});
</script>
